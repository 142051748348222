import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import {convertUTCtoGB, convertUTCToTransactionsContextTimeZone} from 'components/utils';
import { amountFormattedValue } from 'services/utils';
import './PopUpScheme.scss';

const getTransactionDetailsFields = (i18n, obligation) => {
  const createdAt = convertUTCToTransactionsContextTimeZone(obligation.created_at);
  const updatedAt = convertUTCToTransactionsContextTimeZone(obligation.updated_at);

  return [
    {
      label: i18n.getMessage('paymentObligation.info.label.obligationId'),
      value: obligation.obligation_id
    },
    {
      label: i18n.getMessage('paymentObligation.info.label.createdDate'),
      value: `${createdAt.date} ${createdAt.time}`
    },
    {
      label: i18n.getMessage('paymentObligation.info.label.updatedDate'),
      value: `${updatedAt.date} ${updatedAt.time}`
    },
    {
      label: i18n.getMessage('paymentObligation.info.label.iban'),
      value: obligation.iban
    },
    {
      label: i18n.getMessage('paymentObligation.info.label.paymentObligationStatus'),
      value: i18n.getMessage(`paymentObligation.status.${obligation.status}`)
    },
    {
      label: i18n.getMessage('paymentObligation.info.label.amount'),
      value: `${amountFormattedValue(obligation.amount)} ${obligation.currency}`
    },
    {
      label: i18n.getMessage('paymentObligation.info.label.comment'),
      value: obligation.comment
    }
  ].filter((field) => field.value || field.value === 0);
};

const PopUpTransactionScheme = ({ selectedObligation, onClose }) => {
  const i18n = useContext(i18nContext);
  const transactionDetailsFields = getTransactionDetailsFields(i18n, selectedObligation);

  const schemeLine = (label, value) => {
    return (
      <div key={label} className={'scheme-line'}>
        <p className={'left'}>{label}:</p>
        <p className={'center'}>{value}</p>
      </div>
    );
  };

  return (
    <div className={'scheme-wrapper'}>
      <div className={'scheme-header'}>
        <p className={'account'}>{i18n.getMessage('popUp.paymentObligationDetails')}</p>
        <CloseIcon className={'scheme-close-icon'} onClick={onClose} />
      </div>
      {transactionDetailsFields.map((field) => schemeLine(field.label, field.value))}
    </div>
  );
};

PopUpTransactionScheme.propTypes = {
  selectedObligation: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PopUpTransactionScheme;
