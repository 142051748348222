import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconCheckCircle } from 'assets/check-circle.svg';
import { ReactComponent as IconPassword } from 'assets/iconButtons/eye.svg';
import { ReactComponent as IconPasswordOff } from 'assets/iconButtons/eye-off.svg';
import { ReactComponent as IconError } from 'assets/iconButtons/warning.svg';
import { PASSWORD_SPECIAL_SYMBOLS } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { passwordSpecialSymbolsRegExp } from 'services/utils';
import { classNames } from 'uikit/utils';
import './PasswordInput.scss';

const PasswordInput = ({
  className,
  value,
  onChange,
  onBlur,
  name,
  label,
  placeholder,
  autoComplete,
  isDisabled,
  isRequired,
  initialStatus,
  error,
  isApiError,
  setIsPasswordVerified
}) => {
  const i18n = useContext(i18nContext);
  const [inputType, setInputType] = useState('password');
  const [isValidationCriteriaError, setIsValidationCriteriaError] = useState(false);
  const [validationCriteria, setValidationCriteria] = useState({
    lowerCase: false,
    upperCase: false,
    number: false,
    length: false,
    specialSymbol: false
  });
  const inputClasses = classNames({
    'input-wrapper': true,
    hit: !error && !isApiError && !isValidationCriteriaError && !!initialStatus,
    error: !!error || !!isApiError || isValidationCriteriaError,
    icon: true,
    disabled: isDisabled,
    [className]: true
  });

  const toggleShowingPassword = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const validatePassword = (password) => {
    const newValidationCriteria = {
      lowerCase: /[a-z]/.test(password),
      upperCase: /[A-Z]/.test(password),
      number: /[0-9]/.test(password),
      length: password.length >= 7 && password.length <= 30,
      specialSymbol: passwordSpecialSymbolsRegExp.test(password)
    };
    setValidationCriteria(newValidationCriteria);
  };

  const hasValidationError = () => {
    return Object.values(validationCriteria).some((value) => value === false);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    onChange(e);
    validatePassword(newValue);
  };

  const handleInputOnBlur = () => {
    const isValid = !hasValidationError();

    setIsValidationCriteriaError(!isValid);
    setIsPasswordVerified(isValid);
    onBlur();
  };

  return (
    <div className={inputClasses}>
      {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
      <input
        className={'input'}
        type={inputType}
        value={value}
        onChange={handleInputChange}
        onBlur={handleInputOnBlur}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        disabled={isDisabled}
        required={isRequired}
      />
      <div
        role={'button'}
        tabIndex={0}
        onKeyDown={toggleShowingPassword}
        onClick={toggleShowingPassword}
        className={`input-icon eye ${error || isValidationCriteriaError ? 'error' : ''}`}
      >
        {inputType === 'text' ? <IconPassword /> : <IconPasswordOff />}
        {error && <IconError />}
      </div>
      {error && <p className={'input-sub-text input-error'}>{error}</p>}
      <div className={'criteria-list'}>
        {Object.keys(validationCriteria).map((criteria, index) => (
          <span
            key={`${criteria} ${index}`}
            className={`criteria ${
              validationCriteria[criteria]
                ? 'valid'
                : isValidationCriteriaError && !validationCriteria[criteria]
                  ? 'invalid'
                  : ''
            }`}
          >
            <IconCheckCircle />
            {i18n.getMessage(`validation.password.${criteria}`, { specialSymbols: PASSWORD_SPECIAL_SYMBOLS })}
          </span>
        ))}
      </div>
    </div>
  );
};

PasswordInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  initialStatus: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isApiError: PropTypes.bool,
  setIsPasswordVerified: PropTypes.func
};

export default PasswordInput;
